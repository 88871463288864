import React from 'react';
import './Team.css';

import image111 from '../..//images/staff6.svg';
import image222 from '../..//images/staff7.svg';
import image333 from '../..//images/staff8.svg';
import image444 from '../..//images/staff9.svg';
import image555 from '../..//images/staff10.svg';
import image667 from '../..//images/staff1.svg';
import image777 from '../..//images/staff2.svg';
import image888 from '../..//images/staff3.svg';
import image999 from '../..//images/staff4.svg';
import image101010 from '../..//images/staff5.svg';

const teamMembers = [
  { name: 'Лиана Кусикян', title: 'Генеральный Директор', image: image111 },
  { name: 'Сурен Аветисян', title: 'Заместитель Генерального Директора', image: image222 },
  { name: 'Азина Степанян', title: 'Администратор проекта', image: image333 },
  { name: 'Микаел Пашаян', title: 'Соучредитель, Старший консультант', image: image444 },
  { name: 'Стивен Каллахан', title: 'Международный консультант', image: image555 },
  { name: 'Армен Эльчиян', title: 'Старший консультант', image: image667 },
  { name: 'Георгий Табуашвили', title: 'Международный консультант', image: image777 },
  { name: 'Арман Маргарян', title: 'Бизнес аналитик', image: image888 },
  { name: 'Наира Маргарян', title: 'Бизнес аналитик', image: image999 },
  { name: 'Армине Хачикян', title: 'Бизнес аналитик', image: image101010 },
];

const Team = () => {
    return (
      <div className="team-container">
        <h1>Наша Команда</h1>
        <div className="team-row">
          {teamMembers.slice(0, 3).map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
        <div className="team-row">
          {teamMembers.slice(3, 6).map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
        <div className="team-row">
          {teamMembers.slice(6, 10).map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Team;
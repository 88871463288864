import React from 'react';
import './Masshtab.css';
import masshtabImg from '../../images/masshtab.png'

const Masshtab = () => {
  return (
    <div className="container7">
      <div className="text-content7">
        <h1>Mashtab Consultancy</h1>
        <p>Scale Consultancy LLC, founded in 2009, offers consulting services in the areas of project management, business literacy and business analysis to both individuals and legal entities.</p>
        <p>Our company profile includes professional consulting services in the field of practical assessment, strategic planning and application of best practices according to the requirements and needs of our clients.</p>
      </div>
      {/* <div className="image-content"> */}
        <img src={masshtabImg} alt="Buildings" id='imgC'/>
      {/* </div> */}
    </div>
  );
};

export default Masshtab;

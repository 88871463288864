import Header from "./engComponents/header";
import MainSection from "./engComponents/mainSection";
import AboutSection from "./engComponents/aboutSection";
import AccordionSection from './engComponents/AccordionSection'
import Abilities from './engComponents/Abilities'
import Projects from "./engComponents/Projects";
import ContactForm from "./engComponents/ContactForm";
import Footer from './engComponents/Footer'
import BackToTopButton from "./BackToTopButton";


const Eng = () => {
    return (
        <div>
           <Header/>
            <MainSection/>
            <AboutSection/>
            <AccordionSection/>
            <Abilities/>
            <Projects/>
            <ContactForm/>
            <Footer/>
            <BackToTopButton/>
        </div>
    );
  };
  

export default Eng;
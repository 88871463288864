import Header from "./rusComponents/header";
import MainSection from "./rusComponents/mainSection";
import AboutSection from "./rusComponents/aboutSection";
import AccordionSection from './rusComponents/AccordionSection'
import Abilities from './rusComponents/Abilities'
import Projects from "./rusComponents/Projects";
import ContactForm from "./rusComponents/ContactForm";
import Footer from './rusComponents/Footer'
import BackToTopButton from "./BackToTopButton";
const Rus = () => {
    return (
        <div>
            <Header/>
            <MainSection/>
            <AboutSection/>
            <AccordionSection/>
            <Abilities/>
            <Projects/>
            <ContactForm/>
            <Footer/>
            <BackToTopButton/>
        </div>
    );
  };
  

export default Rus;
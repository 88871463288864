import React from 'react';
import Header from "../../armComponents/header"
import Footer from "../../armComponents/Footer"
import Masshtab from './Masshtab';
import Team from './Team';

const Staff = () => {
  return (
    <div className="Staff">
      <Header/>
      <Masshtab/>
      <Team/>
      <Footer/>
    </div>
  );
};

export default Staff;
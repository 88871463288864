import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';
import './BackToTopButton.css'; // Make sure to create this CSS file for styling

const BackToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <IconButton
            onClick={scrollToTop}
            className={`back-to-top-button ${showButton ? 'show' : 'hide'}`}
        >
            <ArrowUpwardIcon />
        </IconButton>
    );
};

export default BackToTopButton;

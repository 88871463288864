import React from 'react';
import './Projects.css';

const Projects = () => {
  return (
    <div className="projectsContainer5" id='projectsContainer'>
      <div className="headerSection5">
        <p className="subtitle5">Current Projects</p>
        <h1 className="mainTitle5">Check out Our Current Projects and Choose the Best Solution</h1>
      </div>
      <div className="projectsContent5">
        <div className="projectCard5">
          <p>Implementation of Gap Analysis in</p>
          <p>accordance with ISO/IEC</p>
          <p>27001:2013 standards in the State</p>
          <p>Revenue Committee.</p>
        </div>
        <div className="projectCard5">
          <p>Project for the Provision of</p>
          <p>Consulting Services within the</p>
          <p>Framework of Implementation of</p>
          <p>Digital Tax Infrastructure</p>
        </div>
        <div className="projectCard5">
          <p>Customs Administration</p>
          <p>Development Program.</p>
        </div>
      </div>
    </div>
  );
};

export default Projects;

import React, { useState } from 'react';
import './accordionSection.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const AccordionSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sections = [
    {
      title: 'Բիզնես Գործընթացի Վերակառուցում',
      content: 'Մենք կիրառում ենք գործիքի վրա հիմնված ինտեգրված շոշափելի գործիքակազմ եւ համոզված ենք, որ մեր արտադրանքի համար հատուկ եւ գինիպիտույների մեջ փոփոխությունները կկարողանան կազմակերպության գործողության համար:',
      index: '01'
    },
    {
      title: 'Ռազմավարության Մշակում',
      content: 'Մեր ռազմավարության մշակումն օգնում է կազմակերպությունների առաջնորդներին կատարելագործել իրենց առկա ռազմավարությունների մշակումը:',
      index: '02'
    },
    {
      title: 'Հետազոտությունների Կառավարման Լուծումների Իրականացում',
      content: 'Մենք օգնում ենք հետազոտությունների կառավարման լուծումների իրականացմամբ՝ քաշելու տվյալների կառավարմանը մոտեցումներ՝ համադրված գործնականությամբ եւ կառավարումով:',
      index: '03'
    },
    {
      title: 'Նորարարական Խորհրդատվություն',
      content: 'Նորարարական խորհրդատվական ծառայությունները նախատեսված են ձեր կազմակերպության հաջողության համար՝ առաջարկելով անհատականացված լուծումների եւ աջակցության մեծածավալ մոտեցումներ:',
      index: '04'
    },
    {
      title: 'ESG Խորհրդատվական Ծառայություններ',
      content: 'Մենք առաջարկում ենք ESG (Շրջակա Միջավայր, Սոցիալական եւ Կառավարչական) խորհրդատվական ծառայություններ՝ օգնելու ձեր կազմակերպությանը հասնել ձեր ESG նպատակներին եւ բարելավել ձեր ընդհանուր գործունեությունը:',
      index: '05'
    },
  ];

  return (
    <div className="accordionSection3" id='accordionSection'>
      <div className="header3">
        <p>Իմաց՛ ավելին մեր</p>
        <h2>Առաջարկված Լուծումներ</h2>
      </div>
      <div className="sectionner3">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`section3 ${activeIndex === index ? 'active3' : ''}`}
            onClick={() => handleToggle(index)}
          >
            <div className="sectionHeader3">
              <div className="ii3">
                <span className="index3">{section.index}</span>
                <h3>{section.title}</h3>
              </div>
              {activeIndex === index ? (
                <>
                  <div className="sectionContent3">
                    <p>{section.content}</p>
                  </div>
                  <ArrowOutwardIcon className="arrow3 open3" />
                </>
              ) : (
                <ArrowForwardIcon className="arrow3" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionSection;

import React from 'react';
import './Abilities.css';

const Abilities = () => {
  return (
    <div className="abilitiesContainer4" id='abilitiesContainer'>
      <div className="headerBackground4">
        <div className="headerSection4">
          <p className="subtitle4">Ծառայություններ</p>
          <h1 className="mainTitle4">Մենք Տրամադրում ենք Պրոֆեսիոնալ<br />Խորհրդատվական Ծառայություններ</h1>
        </div>
      </div>
      <div className="contentSection4">
        <div className="leftColumn4">
          <p>Բիզնես Գործընթացի Վերակառուցում</p>
          <p>Նախագծերի Կառավարում</p>
          <p>Եվ Ժամանակավորության Կառավարում</p>
          <p>ESG Բաժնետերերի Հաշտարառություն</p>
          <p>Ֆինանսական և Տեխնիկական Աուդիտ</p>
          <p>Հաշվետվություններ և Խորհրդատվություն</p>
        </div>
        <div className="rightColumn4">
          <p>Ռիսկի Աջակցում</p>
          <p>Նոր Բիզնես Մոդելների Ստեղծում</p>
          <p>Կազմակերպության Պրոֆեսիոնալ և Բիզնես Վերանայում</p>
          <p>Իրավական Խորհրդատվություն</p>
          <p>Հաշվապահություն և Հարկային Խորհրդատվություն</p>
        </div>
      </div>
    </div>
  );
};

export default Abilities;

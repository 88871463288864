import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contactFormContainer6" id='contactFormContainer'>
      <div className="formHeader6">
        <p className="subtitle6">Контакты</p>
        <h1 className="mainTitle6">Запрос на Консультацию</h1>
      </div>
      <div className="formContent6">
        <div className="formFields6">
          <div className="row6">
            <input type="text" className="inputField6" placeholder="Ваше Имя" />
            <input type="text" className="inputField6" placeholder="Ваш электронный адрес" />
          </div>
          <div className="row6">
            <input type="text" className="inputField6" placeholder="Телефон" />
            <input type="text" className="inputField6" placeholder="Тема" />
          </div>
          <textarea className="inputField6" placeholder="Ваше сообщение (необязательно)"></textarea>
          <button className="submitButton6">Отправить</button>
        </div>
        <div className="contactInfo6">
          <p><strong>Телефон</strong> 094556548</p>
          <p><strong>Эл. адрес</strong> <a href="mailto:info@masshtab.am">info@masshtab.am</a></p>
          <p><strong>Адрес</strong> ул. Сармена 72, Ереван</p>
          <p><strong>Карта</strong><a href="https://yandex.com/maps/-/CDvPZSmB" target="_blank"> Смотреть карту</a></p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

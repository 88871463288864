import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './header.css';
import logo from '../images/logo.svg';
import russianFlag from '../images/rus.png';
import armFlag from '../images/arm.png';
import usFlag from '../images/eng.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleFlagClick = (lang) => {
    const currentPath = location.pathname;
    const isStaffPage = currentPath.startsWith('/staff');
    const basePath = isStaffPage ? '/staff' : '';

    navigate(`${basePath}/${lang}`);
  };

  const handleSectionClick = (hash) => {
    const currentPath = location.pathname;
    const langPath = currentPath.split('/')[2]; // Get the language part after /staff/

    if (currentPath.startsWith('/staff')) {
      navigate(`/${langPath}${hash}`, { replace: true });
    } else {
      navigate(`${hash}`, { replace: true });
    }
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="logo">
          <a href="\eng"><img src={logo} alt="Mashtab Consultancy" /></a>
        </div>
        <nav className="desktop-nav">
          <ul>
            <li><a href="/staff/eng">Company</a></li>
            <li><a onClick={() => handleSectionClick('#abilitiesContainer')}>Services</a></li>
            <li><a onClick={() => handleSectionClick('#projectsContainer')}>Current Projects</a></li>
          </ul>
        </nav>
        <div className="language-switch">
          <img src={armFlag} alt="Armenian" onClick={() => handleFlagClick('arm')} />
          <img src={russianFlag} alt="Russian" onClick={() => handleFlagClick('rus')} />
          <img src={usFlag} alt="English" onClick={() => handleFlagClick('eng')} />
          <a onClick={() => handleSectionClick('#contactFormContainer')} id="contacts-button">Contact us</a>
        </div>
        <div className='mic'>
          <MenuIcon className="menu-icon" onClick={toggleMenu} />
        </div>
      </header>

      {menuOpen && (
        <div className="mobile-menu">
          <CloseIcon className="close-icon" onClick={toggleMenu} />
          <nav>
  <ul>
    <li><a href="/staff/eng">Company</a></li>
    <li><a onClick={() => { handleSectionClick('#abilitiesContainer'); toggleMenu(); }}>Services</a></li>
    <li><a onClick={() => { handleSectionClick('#projectsContainer'); toggleMenu(); }}>Current Projects</a></li>
    <li><a onClick={() => { handleSectionClick('#contactFormContainer'); toggleMenu(); }}>Contact us</a></li>
  </ul>
</nav>
<div className="language-switch-mobile">
  <img src={armFlag} alt="Armenian" onClick={() => { handleFlagClick('arm'); toggleMenu(); }} />
  <img src={russianFlag} alt="Russian" onClick={() => { handleFlagClick('rus'); toggleMenu(); }} />
  <img src={usFlag} alt="English" onClick={() => { handleFlagClick('eng'); toggleMenu(); }} />
</div>

        </div>
      )}
    </>
  );
};

export default Header;

import React from 'react';
import './aboutSection.css';
import aboutImg from '../images/about.png';
import frame1 from '../images/rusFrames/Frame 10.png';
import frame2 from '../images/rusFrames/Frame 11.png';
import frame3 from '../images/rusFrames/Frame 12.png';
import frame4 from '../images/rusFrames/Frame 13.png';
import frame5 from '../images/rusFrames/Frame 14.png';

const AboutSection = () => {
  return (
    <div className="aboutSection" id='aboutSection'>
      <div className="textPart2">
        <p className="heading">О Нас</p>
        <h1>Мы Поддерживаем Наших 
          <br />
          Клиентов в Достижении
          <br />  
          Наилучших Результатов
        </h1>
      </div>
      <div className="mainPart2">
        <div className="abImg">
          <img src={aboutImg} alt="aboutimg" />
        </div>
        <div className="abDescription">
          <div>
            <p className="descriptionText">Наша команда помогает компаниям в создании и продвижении проектов и программ, а также в изменении возможностей управления в соответствии с их стратегическими целями и потребностями. Мы также осуществляем анализ и определяем те управленческие подходы, которые необходимы нашим клиентам для успешной трансформации своего бизнеса.</p>
          </div>
          <div>
            <h3 className="offerHeading">Что мы предлагаем нашим клиентам</h3>
            <div className="frames">
              <div className="frame"><img src={frame1} alt="" /></div>
              <div className="frame"><img src={frame2} alt="" /></div>
              <div className="frame"><img src={frame3} alt="" /></div>
              <div className="frame"><img src={frame4} alt="" /></div>
              <div className="frame"><img src={frame5} alt="" /></div>
            </div>
          </div>
          <a  href="/staff/rus" className="aboutButton">О Компании</a>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

import React from 'react';
import './Projects.css';

const Projects = () => {
  return (
    <div className="projectsContainer5" id='projectsContainer'>
      <div className="headerSection5">
        <p className="subtitle5">Նախագծեր</p>
        <h1 className="mainTitle5">Ճանապարհը Մեր Մրցունակ Նախագծերին</h1>
      </div>
      <div className="projectsContent5">
        <div className="projectCard5">
          <p>Պրոյեկտի Ելակետներ</p>
          <p>Կովիդի ISO/IEC 27001:2013 Ստանդարտ Պաշտպանություն</p>
          <p>Համակարգված Ելք</p>
          <p>Վիրտուալ Ինֆորմացիա</p>
        </div>
        <div className="projectCard5">
          <p>Թվային Համակարգ</p>
          <p>Երաշխավորված Ներդրում</p>
          <p>Երաշխավորված Խորհրդատվական Ծառայություններ</p>
          <p>Նախագծված Նախագիծ</p>
        </div>
        <div className="projectCard5">
          <p>Սարքավորանքի Պատրաստում</p>
          <p>Զարգացած Ծրագիր</p>
        </div>
      </div>
    </div>
  );
};

export default Projects;

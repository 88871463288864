import React from 'react';
import './aboutSection.css';
import aboutImg from '../images/about.png';
import frame1 from '../images/Frame 10.png';
import frame2 from '../images/Frame 11.png';
import frame3 from '../images/Frame 12.png';
import frame4 from '../images/Frame 13.png';
import frame5 from '../images/Frame 14.png';

const AboutSection = () => {
  return (
    <div className="aboutSection" id='aboutSection'>
      <div className="textPart2">
        <p className="heading">Մեր մասին</p>
        <h1>Մենք առաջարկում ենք 
          <br />
          մեր հաճախորդներին
          <br />  
          առավելագույնը
        </h1>
      </div>
      <div className="mainPart2">
        <div className="abImg">
          <img src={aboutImg} alt="aboutimg" />
        </div>
        <div className="abDescription">
          <div>
            <p className="descriptionText">Մեր թիմը աջակցում է կազմակերպություններին ստեղծել և զարգացնել իրենց նախագծերը, ծրարգրերը և փոխել կառավարչական կարողություններն այնպես, որ համապատասխանեն նրանց ռազմավարական կարիքներին և նպատակներին: Մենք մեր հաճախորդների համար նաև վերլուծում և հստակեցնում ենք կառավարչական մոտեցումները, որոնք անհրաժեշտ են հաջողված փոփոխությունների համար:</p>
          </div>
          <div>
            <h3 className="offerHeading">Մենք առաջարկում ենք մեր հաճախորդներին՝</h3>
            <div className="frames">
              <div className="frame"><img src={frame1} alt="" /></div>
              <div className="frame"><img src={frame2} alt="" /></div>
              <div className="frame"><img src={frame3} alt="" /></div>
              <div className="frame"><img src={frame4} alt="" /></div>
              <div className="frame"><img src={frame5} alt="" /></div>
            </div>
          </div>
          <a href="/staff/arm" className="aboutButton">Կազմակերպության մասին</a>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

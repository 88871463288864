import React from 'react';
import './Footer.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Footer = () => {
  return (
    <div className="footerContainer6" id='footerContainer'>
      <div className="footerContent6">
        <div className="footerColumn6">
          <h2>Mashtab <span className="highlight6">Consultancy</span></h2>
          <p>Մենք առաջարկում ենք մեր հաճախորդներին առավելագույն մասշտաբի հասնել բիզնեսի կառավարման, ստրատեգիական օպտիմալացմանը:</p>
        </div>
        <div className="footerColumn6">
          <h3>Կառավարման Կառույց</h3>
          <p>Ծառայություններ</p>
          <p>Շտեմարաններ</p>
          <p>Կապ մեզ հետ</p>
        </div>
        <div className="footerColumn6">
          <h3>Հասցե</h3>
          <p>Երևան, Սարմենա 72</p>
          <p>Քարտեզ</p>
          <p>Հեռախոս: 094556548</p>
          <p>Էլ. Հասցե: <a href="mailto:info@masshtab.am">info@masshtab.am</a></p>
        </div>
        <div className="footerColumn6">
          <h3>Տեղեկատվություն</h3>
          <p>Ստացեք վերջին տեղեկությունները մեր էլ.փոստի միջոցով անմիջապես մի քանի վարկյանում:</p>
          <div className="subscription6">
            <div className="subscribeText6">Բաժանորդագրվել</div>
            <ArrowForwardIcon className="subscribeArrow6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

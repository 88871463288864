import React, { useState } from 'react';
import './accordionSection.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const AccordionSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sections = [
    {
      title: 'Business process reengineering',
      content: 'We use a set of tools and techniques based on process management to improve the progress of your business processes and procedures.',
      index: '01'
    },
    {
      title: 'Development strategy',
      content: 'Our strategy development approach strengthens organizations to maximize the full potential of their mission. Through research and business analytics, we identify key issues and opportunities for generating sustainable, long-term strategies. We then plan, prioritize, and organize the key strategies into a roadmap with short-term, mid-term, and long-term horizons.',
      index: '02'
    },
    {
      title: 'Electronic Governance Solutions',
      content: 'We assist in the implementation of e-Governance solutions, enhancing the efficiency and transparency of governmental operations through innovative technologies.',
      index: '03'
    },
    {
      title: 'Innovation consulting',
      content: 'Our innovation consulting services are designed to help your organization foster a culture of innovation, driving growth through creative solutions and cutting-edge technologies.',
      index: '04'
    },
    {
      title: 'ESG Consulting',
      content: 'We offer ESG (Environmental, Social, and Governance) consulting services to help you integrate sustainable practices into your business operations, ensuring long-term viability and compliance.',
      index: '05'
    },
  ];

  return (
    <div className="accordionSection3" id='accordionSection'>
      <div className="header3">
        <p>Why Choose Us</p>
        <h2>Solutions That Inspire Progress</h2>
      </div>
      <div className="sectionner3">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`section3 ${activeIndex === index ? 'active3' : ''}`}
            onClick={() => handleToggle(index)}
          >
            <div className="sectionHeader3">
              <div className="ii3">
                <span className="index3">{section.index}</span>
                <h3>{section.title}</h3>
              </div>
              {activeIndex === index ? (
                <>
                  <div className="sectionContent3">
                    <p>{section.content}</p>
                  </div>
                  <ArrowOutwardIcon className="arrow3 open3" />
                </>
              ) : (
                <ArrowForwardIcon className="arrow3" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionSection;

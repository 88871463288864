import React from 'react';
import './Team.css';

import image111 from '../..//images/staff6.svg';
import image222 from '../..//images/staff7.svg';
import image333 from '../..//images/staff8.svg';
import image444 from '../..//images/staff9.svg';
import image555 from '../..//images/staff10.svg';
import image667 from '../..//images/staff1.svg';
import image777 from '../..//images/staff2.svg';
import image888 from '../..//images/staff3.svg';
import image999 from '../..//images/staff4.svg';
import image101010 from '../..//images/staff5.svg';

const teamMembers = [
  { name: 'Liana Kusikyan', title: 'CEO', image: image111 },
  { name: 'Suren Avetisyan', title: 'Deputy General Director', image: image222 },
  { name: 'Azina Stepanyan', title: 'Project Administrator', image: image333 },
  { name: 'Mikael Pashayan', title: 'Co-founder, Senior Consultant', image: image444 },
  { name: 'Stephen Callahan', title: 'International consultant', image: image555 },
  { name: 'Armen Elchiyan', title: 'Senior consultant', image: image667 },
  { name: 'Giorgi Tabuashvili', title: 'International consultant', image: image777 },
  { name: 'Arman Margaryan', title: 'Business analyst', image: image888 },
  { name: 'Naira Margaryan', title: 'Business analyst', image: image999 },
  { name: 'Armine Khachikyan', title: 'Business analyst', image: image101010 },
];

const Team = () => {
    return (
      <div className="team-container">
        <h1>Our team</h1>
        <div className="team-row">
          {teamMembers.slice(0, 3).map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
        <div className="team-row">
          {teamMembers.slice(3, 6).map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
        <div className="team-row">
          {teamMembers.slice(6, 10).map((member, index) => (
            <div key={index} className="team-member">
              <img src={member.image} alt={member.name} />
              <h2>{member.name}</h2>
              <p>{member.title}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Team;
import React, { useState } from 'react';
import './accordionSection.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const AccordionSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sections = [
    {
      title: 'Реинжиниринг бизнес процесса',
      content: 'Мы используем набор инструментов и техник, основанных на процессном управлении, чтобы усовершенствовать продвижение Ваших бизнес процессов и процедур.',
      index: '01'
    },
    {
      title: 'Стратегия развития',
      content: 'Наша разработка стратегии помогает руководителям организаций улучшить существующую стратегию развития.',
      index: '02'
    },
    {
      title: 'Решения электронного управления',
      content: 'Мы помогаем внедрять решения по управлению исследованиями, сочетая подходы к управлению данными с практичностью и управляемостью.',
      index: '03'
    },
    {
      title: 'Инновационный консалтинг',
      content: 'Инновационные консалтинговые услуги созданы для успеха вашей организации, предлагая комплексный подход к персонализированным решениям и поддержке.',
      index: '04'
    },
    {
      title: 'ESG Консалтинг',
      content: 'Мы предлагаем консультационные услуги по вопросам ESG (экологической, социальной и управленческой деятельности), которые помогут вашей организации достичь целей ESG и улучшить общую производительность.',
      index: '05'
    },
  ];

  return (
    <div className="accordionSection3" id='accordionSection'>
      <div className="header3">
        <p>Почему Выбрать Нас</p>
        <h2>Решения, Которые Вдохновляют Прогресс</h2>
      </div>
      <div className="sectionner3">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`section3 ${activeIndex === index ? 'active3' : ''}`}
            onClick={() => handleToggle(index)}
          >
            <div className="sectionHeader3">
              <div className="ii3">
                <span className="index3">{section.index}</span>
                <h3>{section.title}</h3>
              </div>
              {activeIndex === index ? (
                <>
                  <div className="sectionContent3">
                    <p>{section.content}</p>
                  </div>
                  <ArrowOutwardIcon className="arrow3 open3" />
                </>
              ) : (
                <ArrowForwardIcon className="arrow3" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionSection;

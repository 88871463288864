import React from 'react';
import './Masshtab.css';
import masshtabImg from '../../images/masshtab.png'

const Masshtab = () => {
  return (
    <div className="container7">
      <div className="text-content7">
        <h1>Մասշտաբ Քոնսալթնսի</h1>
        <p>«Մասշտաբ» Խորհրդատվական կազմակերպություն ՍՊԸ-ն տրամադրում է խորհրդատվական ծառայություններ կառավարման, բիզնես գրագիտության և բիզնես վերլուծության ոլորտներում, ինչպես ֆիզիկական անձանց, այնպես նաև կազմակերպություններին:</p>
        <p>Մեր անձնակազմն արհեստավարժ է խորհրդատվական բազմատեսակ ծառայությունների ոլորտում ինչպիսիք են գործնական գնահատումը, ռազմավարության մշակումն ու անհրաժեշտ խորհրդատվությունը՝ համապատասխան մեր հաճախորդների պահանջներին ու նպատակներին:</p>
      </div>
      {/* <div className="image-content"> */}
        <img src={masshtabImg} alt="Buildings" id='imgC'/>
      {/* </div> */}
    </div>
  );
};

export default Masshtab;

import React from 'react';
import './Masshtab.css';
import masshtabImg from '../../images/masshtab.png'

const Masshtab = () => {
  return (
    <div className="container7">
      <div className="text-content7">
        <h1>Масштаб Консалтанси</h1>
        <p>ООО “Масштаб Консалтнси”, основанный в 2009 году, предлагает консалтинговые услуги в сферах управления проектами, бизнес грамотности и бизнес анализа как частным, так и юридическим лицам. </p>
        <p>Профиль нашей компании включает профессиональные консалтинговые услуги в области практичной оценки, стратегического планирования и применение лучших практик согласно требованиям и потребностям наших клиентов.</p>
      </div>
      {/* <div className="image-content"> */}
        <img src={masshtabImg} alt="Buildings" id='imgC'/>
      {/* </div> */}
    </div>
  );
};

export default Masshtab;

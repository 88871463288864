import React from 'react';
import MainImg from '../images/main.png';
import '../armComponents/mainSectionn.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MainSection = () => {
  return (
    <div className='mainSection' id='mainSection'>
      <div className='textPart'>
        <div className='f1'>
          <div className='a2'>
            <h1>Ճանապարհ դեպի</h1>
            <h1>հաջողություն մեր փորձագետների օգնությամբ</h1>
          </div>
          <div className='a3'>
            <h1>Մեր ծառայությունները</h1>
            <a href="#abilitiesContainer"><ArrowForwardIcon /></a>
          </div>
        </div>
      </div>
      <div className='imgPart' id='imgPart'>
        <p>Գաղափարից մինչև իրականացում: Ապագայի համար տեսլականի մշակում:</p>
        <img src={MainImg} alt="MainIMg" />
      </div>
    </div>
  );
};

export default MainSection;

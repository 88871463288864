import React from 'react';
import './Abilities.css';

const Abilities = () => {
  return (
    <div className="abilitiesContainer4" id='abilitiesContainer'>
      <div className="headerBackground4">
        <div className="headerSection4">
          <p className="subtitle4">Услуги</p>
          <h1 className="mainTitle4">Мы предоставляем широкий спектр<br />Профессиональных консалтинговых услуг</h1>
        </div>
      </div>
      <div className="contentSection4">
        <div className="leftColumn4">
          <p>Анализ Бизнес Процесса</p>
          <p>Управление Проектами</p>
          <p>Решения Электронного Управления</p>
          <p>ESC Консалтинг</p>
          <p>Развитие Функциональных и Технических Характиристик</p>
          <p>Развитие Инновационных и Цифровых Стратегий</p>
        </div>
        <div className="rightColumn4">
          <p>Стратегический Анализ и Отчеты</p>
          <p>Обеспечение качества</p>
          <p>Создание Новых Бизнес Моделей</p>
          <p>Изменение Управления и Трансформация Бизнеса</p>
          <p>Юридический Консалтинг</p>
          <p>Консалтинг по Бугалтерии и Таможенным Регулированиям</p>
        </div>
      </div>
    </div>
  );
};

export default Abilities;

import React from 'react';
import './aboutSection.css';
import aboutImg from '../images/about.png';
import frame1 from '../images/engFrames/Frame 10.png';
import frame2 from '../images/engFrames/Frame 11.png';
import frame3 from '../images/engFrames/Frame 12.png';
import frame4 from '../images/engFrames/Frame 13.png';
import frame5 from '../images/engFrames/Frame 14.png';

const AboutSection = () => {
  return (
    <div className="aboutSection" id='aboutSection'>
      <div className="textPart2">
        <p className="heading">About Us</p>
        <h1>We Support 
          <br />
          Our Clients
          <br />  
          in Achieving the Best Results
        </h1>
      </div>
      <div className="mainPart2">
        <div className="abImg">
          <img src={aboutImg} alt="aboutimg" />
        </div>
        <div className="abDescription">
          <div>
            <p className="descriptionText">Our team assists companies in creating and promoting projects and programs, as well as in changing management capabilities in accordance with their strategic goals and needs. We also analyze and identify the management approaches that our clients need to successfully transform their businesses.</p>
          </div>
          <div>
            <h3 className="offerHeading">What We Offer to Our Clients</h3>
            <div className="frames">
              <div className="frame"><img src={frame1} alt="" /></div>
              <div className="frame"><img src={frame2} alt="" /></div>
              <div className="frame"><img src={frame3} alt="" /></div>
              <div className="frame"><img src={frame4} alt="" /></div>
              <div className="frame"><img src={frame5} alt="" /></div>
            </div>
          </div>
          <a href="/staff/eng" className="aboutButton">About company</a>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

import React from 'react';
import Header from "../../engComponents/header"
import Footer from "../../engComponents/Footer"
import Masshtab from '../../engComponents/staffPage/Masshtab';
import Team from '../../engComponents/staffPage/Team';

const Staff = () => {
  return (
    <div className="Staff">
      <Header/>
      <Masshtab/>
      <Team/>
      <Footer/>
    </div>
  );
};

export default Staff;
import Header from "./armComponents/header";
import MainSection from "./armComponents/mainSection";
import AboutSection from "./armComponents/aboutSection";
import AccordionSection from './armComponents/AccordionSection'
import Abilities from './armComponents/Abilities'
import Projects from "./armComponents/Projects";
import ContactForm from "./armComponents/ContactForm";
import Footer from './armComponents/Footer'
import BackToTopButton from "./BackToTopButton";
const Arm = () => {
    return (
        <div>
            <Header/>
            <MainSection/>
            <AboutSection/>
            <AccordionSection/>
            <Abilities/>
            <Projects/>
            <ContactForm/>
            <Footer/>
            <BackToTopButton/>
        </div>
    );
  };
  

export default Arm;
import React from 'react';
import './Abilities.css';

const Abilities = () => {
  return (
    <div className="abilitiesContainer4" id='abilitiesContainer'>
      <div className="headerBackground4">
        <div className="headerSection4">
          <p className="subtitle4">Services</p>
          <h1 className="mainTitle4">We provide a wide range<br />professional consulting services</h1>
        </div>
      </div>
      <div className="contentSection4">
        <div className="leftColumn4">
          <p>Business Process Analysis</p>
          <p>Project management</p>
          <p>Electronic Governance Solutions</p>
          <p>ESC Consulting</p>
          <p>Development of Functional and Technical Characteristics</p>
          <p>Development of Innovation and Digital Strategies</p>
        </div>
        <div className="rightColumn4">
          <p>Strategic Analysis and Reports</p>
          <p>Quality assurance</p>
          <p>Creation of New Business Models</p>
          <p>Management Change and Business Transformation</p>
          <p>Legal Consulting</p>
          <p>Consulting on Accounting and Customs Regulations</p>
        </div>
      </div>
    </div>
  );
};

export default Abilities;

import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  return (
    <div className="contactFormContainer6" id='contactFormContainer'>
      <div className="formHeader6">
        <p className="subtitle6">Կապ մեզ հետ</p>
        <h1 className="mainTitle6">Խորհրդատվության հարցում</h1>
      </div>
      <div className="formContent6">
        <div className="formFields6">
          <div className="row6">
            <input type="text" className="inputField6" placeholder="Անուն" />
            <input type="text" className="inputField6" placeholder="Էլ. Հասցե" />
          </div>
          <div className="row6">
            <input type="text" className="inputField6" placeholder="Հեռախոսահամար" />
            <input type="text" className="inputField6" placeholder="Թեմա" />
          </div>
          <textarea className="inputField6" placeholder="Հաղորդագրություն"></textarea>
          <button className="submitButton6">Ուղարկել</button>
        </div>
        <div className="contactInfo6">
          <p><strong>Հեռախոս:</strong> 094556548</p>
          <p><strong>Էլ. Հասցե:</strong> <a href="mailto:info@masshtab.am">info@masshtab.am</a></p>
          <p><strong>Հասցե:</strong> Երևան, Սայաթնովա 72</p>
          <p><strong>Քարտեզ:  <a href="https://yandex.com/maps/-/CDvPZSmB" target="_blank">Նայել քարտեզ</a></strong></p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
